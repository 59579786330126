<template>
  <div>
    <v-card :loading="loading">
      <v-system-bar>{{ title }}</v-system-bar>
      <v-calendar
        v-bind="$attrs"
        ref="calendar"
        first-interval="7"
        interval-count="14"
        :value="focus"
        :weekdays="weekdays"
        locale="de-CH"
        @click:event="showEvent"
        :type="type"
        :events="events"
        :locale-first-day-of-year="4"
        @change="change"
      >
        <template v-slot:event="{ event, eventParsed, eventSummary }">
          <template v-if="event.event">
            <v-badge
              inline
              bordered
              left
              dot
              :color="eventColor(event.event)"
              v-if="event.event && eventColor(event.event)"
            >
              <component
                :is="{ render: eventSummary }"
                :class="event.canceled ? 'canceled' : ''"
                class="ma-1"
              ></component>
            </v-badge>

            <component
              :is="{ render: eventSummary }"
              v-else
              class="mx-1"
            ></component>
          </template>

          <template v-else-if="event.lesson">
            <v-tooltip
              top
              v-for="note in event.lesson.notes"
              :key="'note' + note.id"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-icon small class="float-right mr-1" dark>
                    {{ noteIcon(note) }}
                  </v-icon>
                  <v-icon
                    small
                    class="float-right mr-1"
                    dark
                    v-if="note.smartLearn"
                  >
                    mdi-laptop
                  </v-icon>
                </div>
              </template>
              <span
                >{{ note.type.description }}: {{ note.title }}
                {{ note.smartLearn ? "(Smartlearn)" : "" }}</span
              >
            </v-tooltip>

            <v-tooltip top v-if="event.lesson.absence">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="float-right mr-1"
                  dark
                >
                  mdi-bed
                </v-icon>
              </template>
              <span>Sie waren in dieser Lektion abwesend!</span>
            </v-tooltip>
            <div>
              <v-badge
                inline
                bordered
                dot
                left
                :color="lessonStatusColor(event.lesson.status)"
              >
                <v-tooltip
                  top
                  :disabled="
                    event.lesson.status &&
                    event.lesson.status.code == 'asPlanned'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      :class="{ canceled: event.canceled }"
                    >
                      <strong
                        >{{ event.name
                        }}<span
                          v-if="
                            event.lesson.rooms.length > 0 ||
                            event.lesson.reservations.length > 0
                          "
                        >
                          ({{
                            [
                              ...event.lesson.rooms.map((el) => el.code),
                              ...event.lesson.reservations.map(
                                (el) => "+" + el.room.code
                              ),
                            ].join(", ")
                          }})</span
                        ></strong
                      >
                    </div> </template
                  ><span>{{
                    event.lesson.status
                      ? event.lesson.status.description
                      : "Spezial"
                  }}</span>
                </v-tooltip>
              </v-badge>
              <div style="margin-top: -2px; margin-left: 16px">
                {{ eventParsed.start.time }} –
                {{ eventParsed.end.time }}
              </div>
            </div>
          </template>

          <div
            v-else
            :class="{ canceled: event.canceled }"
            style="padding-left: 3px"
          >
            <strong>{{ event.name }} </strong><br />
            <div style="margin-top: -2px">
              {{ eventParsed.start.time }} –
              {{ eventParsed.end.time }}
            </div>
          </div>
        </template>
        <template v-slot:day-body="{ date }">
          <div
            v-if="cal && date == cal.times.now.date"
            class="v-current-time"
            :style="{ top: nowY }"
          ></div>
        </template>
      </v-calendar>
    </v-card>
    <v-bottom-sheet v-model="dialog" width="500">
      <LessonSheet
        v-if="selectedEvent && selectedEvent.lesson"
        :day="selectedEvent.day"
        :register="register"
        :value="selectedEvent.lesson"
        :showAbsences="showAbsences"
        @close="dialog = false"
        @updated="fetchData"
      ></LessonSheet>
      <EventSheet
        v-if="selectedEvent && selectedEvent.event"
        :value="selectedEvent.event"
        @close="dialog = false"
      ></EventSheet>
      <HolidaySheet
        v-if="selectedEvent && selectedEvent.holiday"
        :value="selectedEvent.holiday"
        @close="dialog = false"
      ></HolidaySheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  addDays,
  formatDate,
  formatDatespan,
  isCurrent,
  isFuture,
  isPast,
} from "common/utils/date.js";

import { lessonStatusColor, noteIcon } from "common/utils/icons.js";

import EventSheet from "common/components/EventSheet.vue";
import HolidaySheet from "common/components/HolidaySheet.vue";
import LessonSheet from "./LessonSheet.vue";

export default defineComponent({
  name: "RegisterCalendar",
  components: { EventSheet, HolidaySheet, LessonSheet },
  props: {
    focus: { type: String, default: "" },
    showAbsences: { type: Boolean, default: false },
    showEvents: { type: Boolean, default: false },
    person: null,
    schoolClass: null,
    type: { type: String, default: "" },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      items: [],
      register: { days: [] },
      selectedEvent: {},
      start: "",
      end: "",
      ready: false,
    };
  },

  computed: {
    cal() {
      return this.ready ? this.$refs.calendar : null;
    },
    dateSpan() {
      return [this.start, this.end];
    },
    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + "px" : "-10px";
    },
    events() {
      const events = [];
      const holidays = [];
      const lessons = [];

      for (const day of this.register.days) {
        if (day.holiday) {
          if (holidays.some((el) => el.event.id == event.id)) {
            break;
          }
          const eventStart = new Date(day.holiday.startDate);
          const eventEnd = new Date(day.holiday.endDate);

          holidays.push({
            name: day.holiday.name,
            category: "Ferien",
            start: eventStart,
            end: eventEnd,
            startDate: day.holiday.startDate,
            endDate: day.holiday.endDate,
            day: day,
            color: "warning",
            timed: false,
            holiday: day.holiday,
          });
        }
        if (day.lessons) {
          for (const lesson of day.lessons) {
            const eventStart = new Date(day.date + "T" + lesson.startTime);
            const eventEnd = new Date(day.date + "T" + lesson.endTime);

            lessons.push({
              name: lesson.course ? lesson.course.code : "–",
              category: "Lektion",
              startDate: day.date,
              endDate: day.date,
              start: eventStart,
              end: eventEnd,
              day: day,
              color: lesson.future || lesson.current ? "info darken-1" : "info",
              timed: true,
              lesson: lesson,
              canceled:
                lesson.status &&
                (lesson.status.code == "canceledByEvent" ||
                  lesson.status.code == "canceled"),
            });
          }
        }

        if (day.canceledBy) {
          const event = day.canceledBy;
          const eventStart = event.startTime
            ? new Date(event.startDate + "T" + event.startTime)
            : new Date(event.startDate);
          const eventEnd = event.endTime
            ? new Date(event.endDate + "T" + event.endTime)
            : new Date(event.endDate);

          events.push({
            id: event.id,
            name: event.description,
            category: "Termin",
            start: eventStart,
            end: eventEnd,
            startDate: event.startDate,
            endDate: event.endDate,
            day: day,
            color: "success",
            timed: event.startTime && event.endTime,
            event: event,
            canceled: event.canceled,
          });
        }

        if (this.showEvents && day.events) {
          for (const event of day.events) {
            if (events.some((el) => el.id === event.id)) {
              continue;
            }
            const eventStart = event.startTime
              ? new Date(event.startDate + "T" + event.startTime)
              : new Date(event.startDate);
            const eventEnd = event.endTime
              ? new Date(event.endDate + "T" + event.endTime)
              : new Date(event.endDate);

            events.push({
              id: event.id,
              name: event.description,
              category: "Termin",
              start: eventStart,
              end: eventEnd,
              startDate: event.startDate,
              endDate: event.endDate,
              day: day,
              color: event.past ? "success" : "success darken-2",
              timed: event.startTime && event.endTime,
              event: event,
              canceled: event.canceled,
            });
          }
        }
      }
      return [...holidays, ...lessons, ...events];
    },
    title() {
      switch (this.type) {
        case "week":
        case "month":
          return formatDatespan(this.start, this.end);
        default:
          return formatDate(this.focus, true);
      }
    },
    weekdays() {
      if (!this.start || !this.end) {
        return [1, 2, 3, 4, 5];
      }
      const sa = addDays(this.start, 5);
      if (this.events.some((event) => event.startDate == sa)) {
        return [1, 2, 3, 4, 5, 6];
      }
      return [1, 2, 3, 4, 5];
    },
  },
  watch: {
    dateSpan() {
      this.fetchData();
    },
    events() {
      if (
        this.selectedEvent &&
        this.selectedEvent.start &&
        this.selectedEvent.end
      ) {
        const foundEvent = this.events.find(
          (el) =>
            el.name == this.selectedEvent.name &&
            el.start.getTime() === this.selectedEvent.start.getTime() &&
            el.end.getTime() === this.selectedEvent.end.getTime() &&
            el.category == this.selectedEvent.category
        );
        if (foundEvent) {
          this.selectedEvent = foundEvent;
        }
      }
    },
    schoolClass() {
      if (this.schoolClass) {
        this.fetchData();
      }
    },
    person() {
      if (this.person) {
        this.fetchData();
      }
    },
  },
  methods: {
    isCurrent,
    isFuture,
    isPast,

    isNew(item) {
      const deadline = new Date(this.$_currentTerm.startDate).getTime();
      const value = new Date(item.lastModified).getTime();
      return deadline < value;
    },
    lessonStatusColor,
    eventColor(event) {
      if (event.canceled) {
        return "danger";
      }
      if (event.current) {
        return "primary";
      }
      if (event.changed) {
        return "error";
      }
      return "";
    },

    noteIcon,
    showEvent({ nativeEvent, event }) {
      this.selectedEvent = event;
      this.dialog = true;
      nativeEvent.stopPropagation();
    },
    async fetchData() {
      if (!this.start || !this.end) {
        return;
      }

      this.loading = true;
      this.register = { days: [] };
      const end = this.type == "week" ? addDays(this.start, 6) : this.end;
      if (this.person && this.person.id) {
        const type =
          this.person.type.code === "student" ? "student" : "teacher";
        this.register = await this.apiList({
          resource: "register/register",
          query: `${type}=${this.person.id}&startDate=${this.start}&endDate=${end}`,
        });
      }
      if (this.schoolClass && this.schoolClass.id) {
        this.register = await this.apiList({
          resource: "register/register",
          query: `schoolClass=${this.schoolClass.id}&startDate=${this.start}&endDate=${end}`,
        });
      }
      for (const day of this.register.days) {
        if (day.events) {
          day.events = day.events.map((el) => ({
            ...el,
            changed: this.isNew(el),
            current: this.isCurrent(el),
            past: this.isPast(el),
            future: this.isFuture(el),
          }));
        }
      }
      this.loading = false;
    },
    change(event) {
      const newStart = event.start.date;
      const newEnd = this.type == "day" ? newStart : addDays(newStart, 7);
      this.start = newStart;
      this.end = newEnd;
    },
    updateTime() {
      setInterval(() => {
        if (this.cal) {
          this.cal.updateTimes();
        }
      }, 60 * 1000);
    },
  },
  mounted() {
    this.ready = true;
    this.updateTime();
  },
});
</script>
<style scoped lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
  &::before {
    content: "";
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}
.canceled,
.canceled .v-event-summary {
  text-decoration-line: line-through;
}
</style>
