<template>
  <div v-if="schoolClass">
    <RegisterSettings v-model="settings"></RegisterSettings>
    <RegisterTabs
      v-touch="{
        left: () => next(),
        right: () => prev(),
      }"
      v-if="settings.view == 'tab'"
      :schoolClass="schoolClass"
      :showAbsences="settings.showAbsences"
      :showEvents="settings.showEvents"
      :focus="settings.focus"
      @input="(value) => (settings.focus = value)"
    ></RegisterTabs>
    <RegisterLessons
      v-touch="{
        left: () => next(),
        right: () => prev(),
      }"
      v-else-if="settings.view == 'list'"
      :schoolClass="schoolClass"
      :showAbsences="settings.showAbsences"
      :showEvents="settings.showEvents"
      :startDate="findMonday(this.settings.focus)"
      :endDate="addDays(findMonday(this.settings.focus), 6)"
    />
    <RegisterCalendar
      v-else
      :schoolClass="schoolClass"
      :showAbsences="settings.showAbsences"
      :showEvents="settings.showEvents"
      :type="settings.view"
      v-touch="{
        left: () => next(),
        right: () => prev(),
      }"
      :focus="settings.focus"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { findMonday, today, addDays } from "common/utils/date.js";
import RegisterCalendar from "common/components/Register/RegisterCalendar.vue";
import RegisterLessons from "common/components/Register/RegisterLessons.vue";
import RegisterSettings from "common/components/Register/RegisterSettings.vue";
import RegisterTabs from "common/components/Register/RegisterTabs.vue";
export default defineComponent({
  name: "SchoolClassRegister",
  components: {
    RegisterCalendar,
    RegisterLessons,
    RegisterSettings,
    RegisterTabs,
  },
  props: ["schoolClass"],
  data() {
    return {
      settings: {
        focus: today(),
        showEvents: false,
        showAbsences: true,
        view: "list",
      },
    };
  },
  methods: {
    addDays,
    findMonday,
    today,
    prev() {
      this.settings.focus = this.addDays(this.settings.focus, -this.steps);
    },
    next() {
      this.settings.focus = this.addDays(this.settings.focus, this.steps);
    },
  },
});
</script>
