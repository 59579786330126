<template>
  <div>
    <v-toolbar dense flat class="mb-4">
      <v-tabs centered v-model="day">
        <v-tab v-for="(d, index) in days" :tab-value="d" :key="index">{{
          formatDayOfWeek(d)
        }}</v-tab>
      </v-tabs>
    </v-toolbar>

    <RegisterLessons
      v-bind="$attrs"
      :startDate="day"
      :endDate="day"
    ></RegisterLessons>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import RegisterLessons from "common/components/Register/RegisterLessons.vue";
import { findMonday, addDays, formatDayOfWeek } from "common/utils/date.js";

export default defineComponent({
  name: "RegisterTabs",
  components: { RegisterLessons },
  props: {
    focus: String,
  },

  data() {
    return {
      day: null,
    };
  },
  computed: {
    days() {
      const mo = findMonday(this.focus);
      let result = [];
      for (let i = 0; i < 6; i++) {
        result.push(addDays(mo, i));
      }
      return result;
    },
  },
  watch: {
    day() {
      this.$emit("input", this.day);
    },
    focus() {
      this.day = this.focus;
    },
  },
  methods: {
    formatDayOfWeek,
  },
  created() {
    this.day = this.focus;
  },
});
</script>
